import { ERROR_MESSAGES, ERRORS, CHALLENGE_WINDOW_SIZES } from '../config';
import { getOrigin } from '../../../utils/getOrigin';
import base64 from '../../../utils/base64';
export var decodeAndParseToken = function (token) {
    var decodedToken = base64.decode(token);
    try {
        return JSON.parse(decodedToken);
    }
    catch (e) {
        throw new Error('Could not decode token');
    }
};
/**
 * @param value - requires an object containing the result parameters
 * @param type - either 'IdentifyShopper' or 'ChallengeShopper'
 * @returns encoded result
 */
export var encodeResult = function (_a, type) {
    var _b = _a.threeDSCompInd, threeDSCompInd = _b === void 0 ? undefined : _b, _c = _a.transStatus, transStatus = _c === void 0 ? undefined : _c;
    if (!threeDSCompInd && !transStatus) {
        throw new Error('No threeDS2 request details found');
    }
    switch (type) {
        case 'IdentifyShopper':
            return base64.encode(JSON.stringify({ threeDSCompInd: threeDSCompInd }));
        case 'ChallengeShopper':
            return base64.encode(JSON.stringify({ transStatus: transStatus }));
        default:
            throw new Error('No data available to create a result');
    }
};
/**
 * Accepts a size string for the challenge window & returns it if it is valid else returns a default value
 * @param sizeStr - the size string to check the validity of
 * @returns a valid size string
 */
export var validateChallengeWindowSize = function (sizeStr) {
    var sizeString = sizeStr.length === 1 ? "0" + sizeStr : sizeStr;
    var hasSize = Object.prototype.hasOwnProperty.call(CHALLENGE_WINDOW_SIZES, sizeString);
    return hasSize ? sizeString : '01';
};
/**
 * Accepts a size string for the challenge window & returns the corresponding array of w/h values
 * @param sizeStr -
 */
export var getChallengeWindowSize = function (sizeStr) { return CHALLENGE_WINDOW_SIZES[validateChallengeWindowSize(sizeStr)]; };
/**
 *  prepareChallengeData
 *  @param value - requires an object containing the challenge parameters
 *  - challengeToken - challengeToken string received from payments call containing acsTransID, acsURL, messageVerison, expected postMessage URL and threeDSServerTransID
 *  - size - one of five possible challenge window sizes
 *  - notificationURL - the URL notifications are expected to be postMessaged from
 */
export var prepareChallengeData = function (_a) {
    var challengeToken = _a.challengeToken, size = _a.size, notificationURL = _a.notificationURL;
    var decodedChallengeToken = decodeAndParseToken(challengeToken);
    var acsTransID = decodedChallengeToken.acsTransID, acsURL = decodedChallengeToken.acsURL, messageVersion = decodedChallengeToken.messageVersion, threeDSNotificationURL = decodedChallengeToken.threeDSNotificationURL, threeDSServerTransID = decodedChallengeToken.threeDSServerTransID;
    var receivedNotificationURL = notificationURL || threeDSNotificationURL;
    var notificationURLOrigin = getOrigin(receivedNotificationURL);
    return {
        acsURL: acsURL,
        cReqData: {
            acsTransID: acsTransID,
            messageVersion: messageVersion,
            threeDSServerTransID: threeDSServerTransID,
            messageType: 'CReq',
            challengeWindowSize: validateChallengeWindowSize(size)
        },
        iframeSizeArr: getChallengeWindowSize(size),
        postMessageDomain: notificationURLOrigin
    };
};
/**
 *  prepareFingerPrintData
 *   requires an object containing the challenge parameters
 *  @param fingerPrintToken - fingerPrintToken string received from payments call, containing
 *  methodNotificationURL, methodURL and threeDSServerTransID
 *  @param notificationURL - the URL notifications are expected to be postMessaged from
 */
export var prepareFingerPrintData = function (_a) {
    var fingerPrintToken = _a.fingerPrintToken, notificationURL = _a.notificationURL;
    var decodedFingerPrintToken = decodeAndParseToken(fingerPrintToken);
    var threeDSMethodNotificationURL = decodedFingerPrintToken.threeDSMethodNotificationURL, threeDSMethodUrl = decodedFingerPrintToken.threeDSMethodUrl, threeDSServerTransID = decodedFingerPrintToken.threeDSServerTransID;
    var receivedNotificationURL = notificationURL || threeDSMethodNotificationURL;
    var notificationURLOrigin = getOrigin(receivedNotificationURL);
    return {
        serverTransactionID: threeDSServerTransID,
        methodURL: threeDSMethodUrl,
        threedsMethodNotificationURL: receivedNotificationURL,
        postMessageDomain: notificationURLOrigin
    };
};
export var createResolveData = function (dataKey, result, paymentData) {
    var _a;
    return ({
        data: {
            details: (_a = {}, _a[dataKey] = result, _a),
            paymentData: paymentData
        }
    });
};
export var handleErrorCode = function (errorCode) {
    var unknownMessage = ERROR_MESSAGES[ERRORS.UNKNOWN];
    var message = ERROR_MESSAGES[errorCode] || unknownMessage;
    return { errorCode: errorCode, message: message };
};
/**
 *
 * Takes a string and encodes it as a base64url string
 * (https://en.wikipedia.org/wiki/Base64#URL_applications)
 * (See also https://tools.ietf.org/html/rfc7515)
 *
 * @example
 * ```
 * const jsonStr = JSON.stringify( {name:'john', surname:'smith'} );
 * const base64url = encodeBase64URL(jsonStr);
 * ```
 *
 * @param dataStr - data, as a string, to be encoded
 *
 * @returns base64URL - a base64url encoded string
 */
export var encodeBase64URL = function (dataStr) {
    var base64Data = window.btoa(dataStr);
    var base64url = base64Data.split('=')[0]; // Remove any trailing '='s
    base64url = base64url.replace(/\+/g, '-'); // 62nd char of encoding
    base64url = base64url.replace(/\//g, '_'); // 63rd char of encoding
    return base64url;
};
