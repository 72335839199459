import { __assign, __extends } from "tslib";
import { Component } from 'preact';
import { getErrorObject, getFields, getErrorReducer, validFieldsReducer, addTranslationsToObject, getTranslatedErrors, resolvePlaceholders } from './utils';
import { CSF_FIELDS_ARRAY } from './lib/configuration/constants';
import initCSF from './lib';
import handlers from './SecuredFieldsProviderHandlers';
import defaultProps from './defaultProps';
/**
 * SecuredFieldsProvider:
 * Initialises & handles the client-side part of SecuredFields
 */
var SecuredFieldsProvider = /** @class */ (function (_super) {
    __extends(SecuredFieldsProvider, _super);
    function SecuredFieldsProvider(props) {
        var _this = _super.call(this, props) || this;
        _this.setRootNode = function (input) {
            _this.rootNode = input;
        };
        var stateObj = {
            status: 'loading',
            brand: props.type,
            errors: {},
            valid: {},
            data: {},
            cvcRequired: true,
            isSfpValid: false
        };
        _this.state = stateObj;
        _this.originKeyErrorTimeout = null;
        _this.originKeyTimeoutMS = 15000;
        _this.numCharsInCVC = 0;
        // Handlers
        _this.handleOnLoad = handlers.handleOnLoad.bind(_this);
        _this.handleOnConfigSuccess = handlers.handleOnConfigSuccess.bind(_this);
        _this.handleOnFieldValid = handlers.handleOnFieldValid.bind(_this);
        _this.handleOnAllValid = handlers.handleOnAllValid.bind(_this);
        _this.handleOnBrand = handlers.handleOnBrand.bind(_this);
        _this.handleFocus = handlers.handleFocus.bind(_this);
        _this.handleOnError = handlers.handleOnError.bind(_this);
        _this.handleOnNoDataRequired = handlers.handleOnNoDataRequired.bind(_this);
        _this.handleOnAutoComplete = handlers.handleOnAutoComplete.bind(_this);
        _this.processBinLookupResponse = _this.processBinLookupResponse.bind(_this);
        // Bindings for functions exposed to users of this component: SecuredFields & CardInput
        _this.setFocusOn = _this.setFocusOn.bind(_this);
        _this.updateStyles = _this.updateStyles.bind(_this);
        _this.handleUnsupportedCard = _this.handleUnsupportedCard.bind(_this);
        _this.showValidation = _this.showValidation.bind(_this);
        _this.destroy = _this.destroy.bind(_this);
        return _this;
    }
    SecuredFieldsProvider.prototype.componentDidMount = function () {
        if (this.props.rootNode) {
            this.setRootNode(this.props.rootNode);
        }
        // Find encryptedFields and map them to the values we use to store valid states
        var fields = getFields(this.rootNode);
        var valid = fields.reduce(validFieldsReducer, {});
        this.setState({ valid: valid });
        // Store how many dateFields we are dealing with visually
        this.numDateFields = fields.filter(function (f) { return f.match(/Expiry/); }).length;
        if (fields.length) {
            this.initializeCSF(this.rootNode);
        }
        else {
            this.handleOnNoDataRequired();
        }
    };
    SecuredFieldsProvider.prototype.componentWillUnmount = function () {
        this.csf = null;
    };
    SecuredFieldsProvider.prototype.initializeCSF = function (root) {
        var loadingContext = this.props.loadingContext;
        // For loading securedFields from local server during development
        if (process.env.NODE_ENV === 'development' && process.env.__SF_ENV__ !== 'build') {
            loadingContext = process.env.__SF_ENV__;
        }
        var csfSetupObj = {
            rootNode: root,
            type: this.props.type,
            originKey: this.props.originKey,
            clientKey: this.props.clientKey,
            cardGroupTypes: this.props.groupTypes,
            allowedDOMAccess: this.props.allowedDOMAccess,
            autoFocus: this.props.autoFocus,
            trimTrailingSeparator: this.props.trimTrailingSeparator,
            loadingContext: loadingContext,
            keypadFix: this.props.keypadFix,
            showWarnings: this.props.showWarnings,
            iframeUIConfig: {
                sfStyles: this.props.styles,
                placeholders: __assign(__assign({}, resolvePlaceholders(this.props.i18n)), this.props.placeholders),
                ariaLabels: addTranslationsToObject(this.props.ariaLabels, CSF_FIELDS_ARRAY, 'error', getTranslatedErrors(this.props.i18n))
            },
            callbacks: {
                onLoad: this.handleOnLoad,
                onConfigSuccess: this.handleOnConfigSuccess,
                onFieldValid: this.handleOnFieldValid,
                onAllValid: this.handleOnAllValid,
                onBrand: this.handleOnBrand,
                onError: this.handleOnError,
                onFocus: this.handleFocus,
                onBinValue: this.props.onBinValue,
                onAutoComplete: this.handleOnAutoComplete
            },
            isKCP: this.props.koreanAuthenticationRequired === true
        };
        this.csf = initCSF(csfSetupObj);
    };
    SecuredFieldsProvider.prototype.getChildContext = function () {
        return { i18n: this.props.i18n };
    };
    SecuredFieldsProvider.prototype.handleUnsupportedCard = function (errObj) {
        var hasUnsupportedCard = !!errObj.error;
        this.handleOnError(errObj, hasUnsupportedCard);
        return hasUnsupportedCard;
    };
    SecuredFieldsProvider.prototype.setFocusOn = function (frame) {
        if (this.csf)
            this.csf.setFocusOnFrame(frame);
    };
    SecuredFieldsProvider.prototype.updateStyles = function (stylesObj) {
        if (this.csf)
            this.csf.updateStyles(stylesObj);
    };
    SecuredFieldsProvider.prototype.destroy = function () {
        if (this.csf)
            this.csf.destroy();
    };
    SecuredFieldsProvider.prototype.showValidation = function () {
        var _this = this;
        var _a = this, numDateFields = _a.numDateFields, props = _a.props, state = _a.state;
        Object.keys(state.valid)
            .reduce(getErrorReducer(numDateFields, state), [])
            .forEach(function (field) {
            // For each detected error pass an error object to the handler (calls error callback & sets state)
            _this.handleOnError(getErrorObject(field, props.rootNode, state));
            // Inform the secured-fields instance of which fields have been found to have errors
            if (_this.csf && _this.csf.isValidated) {
                _this.csf.isValidated(field);
            }
        });
    };
    SecuredFieldsProvider.prototype.processBinLookupResponse = function (binValueObject) {
        // Scenarios:
        // RESET (binValueObject === null): The number of digits in number field has dropped below threshold for BIN lookup
        // RESULT (binValueObject.brands.length === 1): binLookup has found a result so inform CSF
        if (this.csf)
            this.csf.brandsFromBinLookup(binValueObject);
    };
    SecuredFieldsProvider.prototype.render = function (props, state) {
        return props.render({ setRootNode: this.setRootNode, setFocusOn: this.setFocusOn }, state);
    };
    SecuredFieldsProvider.defaultProps = defaultProps;
    return SecuredFieldsProvider;
}(Component));
export default SecuredFieldsProvider;
