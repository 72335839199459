import { __assign } from "tslib";
import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import classNames from 'classnames';
import useCoreContext from '../../../../core/Context/useCoreContext';
import styles from './MBWayInput.module.scss';
import './MBWayInput.scss';
import Field from '../../../../components/internal/FormFields/Field';
import { renderFormField } from '../../../../components/internal/FormFields';
import { mbwayValidationRules } from './validate';
import Validator from '../../../../utils/Validator';
function MBWayInput(props) {
    var _this = this;
    var i18n = useCoreContext().i18n;
    var validator = new Validator(mbwayValidationRules);
    var _a = useState({}), errors = _a[0], setErrors = _a[1];
    var _b = useState({}), valid = _b[0], setValid = _b[1];
    var _c = useState(__assign({}, props.data)), data = _c[0], setData = _c[1];
    var getIsValidEmail = function () { return validator.validate('email', 'blur')(data['email']).isValid; };
    var getIsValidPhoneNumber = function () { return validator.validate('phoneNumber', 'blur')(data['phoneNumber']).isValid; };
    var _d = useState('ready'), status = _d[0], setStatus = _d[1];
    this.setStatus = function (newStatus) {
        setStatus(newStatus);
    };
    this.showValidation = function () {
        setErrors(__assign(__assign({}, errors), { email: !getIsValidEmail(), phoneNumber: !getIsValidPhoneNumber() }));
    };
    var handleEventFor = function (key, type) { return function (e) {
        var _a, _b, _c, _d;
        e.preventDefault();
        var val = e.target.value;
        var _e = validator.validate(key, type)(val), value = _e.value, isValid = _e.isValid;
        if (type === 'input') {
            setData(__assign(__assign({}, data), (_a = {}, _a[key] = value, _a)));
            setErrors(__assign(__assign({}, errors), (_b = {}, _b[key] = false, _b)));
        }
        if (type === 'blur') {
            // Length check, below, avoids bug where field, if in error state and then cleared, goes back to being in error state when it loses focus
            // If field is empty it can never be in error - else take the opposite of the isValid value
            var isInError = value.length === 0 ? false : !isValid;
            setErrors(__assign(__assign({}, errors), (_c = {}, _c[key] = isInError, _c)));
        }
        setValid(__assign(__assign({}, valid), (_d = {}, _d[key] = isValid, _d)));
    }; };
    useEffect(function () {
        // If data has been passed - validate
        if (data.email || data.phoneNumber) {
            setValid(__assign(__assign({}, valid), { email: getIsValidEmail(), phoneNumber: getIsValidPhoneNumber() }));
        }
    }, []);
    // Run when state.data, -errors or -valid change
    useEffect(function () {
        props.onChange({ data: data, isValid: valid.email && valid.phoneNumber }, _this);
    }, [data, valid, errors]);
    return (h("div", { className: "adyen-checkout__ach" },
        h(Field, { errorMessage: !!errors.email && i18n.get('shopperEmail.invalid'), label: i18n.get('shopperEmail'), classNameModifiers: ['shopperEmail'], isValid: valid.email }, renderFormField('emailAddress', {
            value: data.email,
            name: 'shopperEmail',
            classNameModifiers: ['large'],
            placeholder: props.placeholders.shopperEmail,
            spellcheck: false,
            required: true,
            autocorrect: 'off',
            onInput: handleEventFor('email', 'input'),
            onChange: handleEventFor('email', 'blur')
        })),
        h(Field, { errorMessage: !!errors.phoneNumber && i18n.get('telephoneNumber.invalid'), label: i18n.get('telephoneNumber'), className: classNames({
                'adyen-checkout__input--phone-number': true
            }), isValid: valid.phoneNumber, 
            // Fixes bug where shopper enters invalid chars - because these get automatically removed, change event does NOT fire
            onFieldBlur: handleEventFor('phoneNumber', 'blur') }, renderFormField('tel', {
            value: data.phoneNumber,
            className: "adyen-checkout__pm__phoneNumber__input " + styles['adyen-checkout__input'],
            placeholder: props.placeholders.telephoneNumber,
            required: true,
            autoCorrect: 'off',
            onInput: handleEventFor('phoneNumber', 'input')
            // onChange: handleEventFor('phoneNumber', 'blur')
        })),
        props.showPayButton && props.payButton({ status: status, label: i18n.get('confirmPurchase') })));
}
MBWayInput.defaultProps = {
    placeholders: { shopperEmail: 'shopper@domain.com', telephoneNumber: '+351 932 123 456' }
};
export default MBWayInput;
