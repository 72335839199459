export var loadScript = function (src, node) {
    if (node === void 0) { node = 'body'; }
    return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = resolve;
        script.onerror = function () {
            script.remove();
            reject(new Error("Unable to load script " + src));
        };
        document.querySelector(node).appendChild(script);
    });
};
export default loadScript;
