import { __assign, __extends } from "tslib";
import { h } from 'preact';
import UIElement from '../UIElement';
import PaypalComponent from './components/PaypalComponent';
import defaultProps from './defaultProps';
import './Paypal.scss';
var PaypalElement = /** @class */ (function (_super) {
    __extends(PaypalElement, _super);
    function PaypalElement(props) {
        var _this = _super.call(this, props) || this;
        _this.paymentData = null;
        _this.resolve = null;
        _this.reject = null;
        _this.handleAction = _this.handleAction.bind(_this);
        _this.updateWithAction = _this.updateWithAction.bind(_this);
        _this.handleCancel = _this.handleCancel.bind(_this);
        _this.handleComplete = _this.handleComplete.bind(_this);
        _this.handleError = _this.handleError.bind(_this);
        _this.handleSubmit = _this.handleSubmit.bind(_this);
        return _this;
    }
    /**
     * Formats the component data output
     */
    PaypalElement.prototype.formatData = function () {
        return {
            paymentMethod: {
                type: PaypalElement.type,
                subtype: PaypalElement.subtype
            }
        };
    };
    PaypalElement.prototype.handleAction = function (action) {
        return this.updateWithAction(action);
    };
    PaypalElement.prototype.updateWithAction = function (action) {
        if (action.paymentMethodType !== this.data.paymentMethod.type)
            throw new Error('Invalid Action');
        if (action.paymentData) {
            this.paymentData = action.paymentData;
        }
        if (action.sdkData && action.sdkData.token) {
            this.resolve(action.sdkData.token);
        }
        else {
            this.reject(new Error('No token was provided'));
        }
        return null;
    };
    Object.defineProperty(PaypalElement.prototype, "isValid", {
        /**
         * Dropin Validation
         *
         * @remarks
         * Paypal does not require any specific Dropin validation
         */
        get: function () {
            return true;
        },
        enumerable: false,
        configurable: true
    });
    PaypalElement.prototype.handleCancel = function (data) {
        this.props.onCancel(data, this.elementRef);
    };
    PaypalElement.prototype.handleComplete = function (details) {
        var state = { data: { details: details, paymentData: this.paymentData } };
        this.props.onAdditionalDetails(state, this.elementRef);
    };
    PaypalElement.prototype.handleError = function (data) {
        this.props.onError(data, this.elementRef);
    };
    PaypalElement.prototype.handleSubmit = function () {
        var _this = this;
        this.submit();
        return new Promise(function (resolve, reject) {
            _this.resolve = resolve;
            _this.reject = reject;
        });
    };
    PaypalElement.prototype.render = function () {
        var _this = this;
        return (h(PaypalComponent, __assign({ ref: function (ref) {
                _this.componentRef = ref;
            } }, this.props, { onCancel: this.handleCancel, onChange: this.setState, onComplete: this.handleComplete, onError: this.handleError, onSubmit: this.handleSubmit })));
    };
    PaypalElement.type = 'paypal';
    PaypalElement.subtype = 'sdk';
    PaypalElement.defaultProps = defaultProps;
    return PaypalElement;
}(UIElement));
export default PaypalElement;
