import { __assign, __extends } from "tslib";
import { h } from 'preact';
import UIElement from '../UIElement';
import ThreeDS2Challenge from './components/Challenge';
/**
 * ThreeDS2ChallengeElement
 */
var ThreeDS2ChallengeElement = /** @class */ (function (_super) {
    __extends(ThreeDS2ChallengeElement, _super);
    function ThreeDS2ChallengeElement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ThreeDS2ChallengeElement.prototype.render = function () {
        return h(ThreeDS2Challenge, __assign({}, this.props, { onComplete: this.onComplete }));
    };
    ThreeDS2ChallengeElement.type = 'threeDS2Challenge';
    ThreeDS2ChallengeElement.defaultProps = {
        dataKey: 'threeds2.challengeResult',
        challengeContainer: null,
        size: '01',
        notificationURL: null,
        challengeToken: null,
        type: 'ChallengeShopper',
        onComplete: function () { }
    };
    return ThreeDS2ChallengeElement;
}(UIElement));
export default ThreeDS2ChallengeElement;
