import { email, telephoneNumber } from '../../../utils/regex';
import { unformatDate } from '../FormFields/InputDate/utils';
export var personalDetailsValidationRules = {
    blur: {
        default: function (value) { return value && value.length > 0; },
        dateOfBirth: function (value) {
            if (!value)
                return false;
            var rawValue = unformatDate(value);
            var ageDiff = Date.now() - Date.parse(rawValue);
            var age = new Date(ageDiff).getFullYear() - 1970;
            return age >= 18;
        },
        telephoneNumber: function (value) { return telephoneNumber.test(value); },
        shopperEmail: function (value) { return email.test(value); }
    }
};
