import { __assign } from "tslib";
import { render } from 'preact';
import getProp from '../utils/getProp';
import EventEmitter from './EventEmitter';
var BaseElement = /** @class */ (function () {
    function BaseElement(props) {
        this.eventEmitter = new EventEmitter();
        this.props = this.formatProps(__assign(__assign({}, this.constructor['defaultProps']), props));
        this._node = null;
        this.state = {};
    }
    /**
     * Executed during creation of any payment element.
     * Gives a chance to any paymentMethod to format the props we're receiving.
     */
    BaseElement.prototype.formatProps = function (props) {
        return props;
    };
    /**
     * Executed on the `data` getter.
     * Returns the component data necessary for the /payments request
     */
    BaseElement.prototype.formatData = function () {
        return {};
    };
    BaseElement.prototype.setState = function (newState) {
        this.state = __assign(__assign({}, this.state), newState);
    };
    Object.defineProperty(BaseElement.prototype, "data", {
        /**
         * Returns the component payment data ready to submit to the Checkout API
         * Note: this does not ensure validity, check isValid first
         */
        get: function () {
            var clientData = getProp(this.props, 'modules.risk.data');
            var conversionId = getProp(this.props, 'modules.analytics.conversionId');
            return __assign(__assign(__assign({}, (clientData && { riskData: { clientData: clientData } })), (conversionId && { conversionId: conversionId })), this.formatData());
        },
        enumerable: false,
        configurable: true
    });
    BaseElement.prototype.render = function () {
        // render() not implemented in the element
        throw new Error('Payment method cannot be rendered.');
    };
    /**
     * Mounts an element into the dom
     * @param domNode - Node (or selector) where we will mount the payment element
     * @returns this - the payment element instance we mounted
     */
    BaseElement.prototype.mount = function (domNode) {
        var node = typeof domNode === 'string' ? document.querySelector(domNode) : domNode;
        if (!node) {
            throw new Error('Component could not mount. Root node was not found.');
        }
        if (this._node) {
            throw new Error('Component is already mounted.');
        }
        this._node = node;
        this._component = this.render();
        render(this._component, node);
        if (this.props.modules && this.props.modules.analytics && !this.props.isDropin) {
            this.props.modules.analytics.send({
                containerWidth: this._node && this._node.offsetWidth,
                component: this.constructor['type'],
                flavor: 'components'
            });
        }
        return this;
    };
    BaseElement.prototype.remount = function (component) {
        if (!this._node) {
            throw new Error('Component is not mounted.');
        }
        var newComponent = component || this.render();
        render(newComponent, this._node, null);
        return this;
    };
    /**
     * Unmounts a payment element from the DOM
     */
    BaseElement.prototype.unmount = function () {
        if (this._node) {
            render(null, this._node);
        }
    };
    BaseElement.defaultProps = {};
    return BaseElement;
}());
export default BaseElement;
