import { __spreadArrays } from "tslib";
import { h } from 'preact';
import classNames from 'classnames';
import CVCHint from './CVCHint';
import Field from '../../../../internal/FormFields/Field';
import useCoreContext from '../../../../../core/Context/useCoreContext';
import styles from '../CardInput.module.scss';
export default function CVC(props) {
    var _a, _b;
    var label = props.label, _c = props.onFocusField, onFocusField = _c === void 0 ? function () { } : _c, _d = props.error, error = _d === void 0 ? false : _d, _e = props.className, className = _e === void 0 ? '' : _e, _f = props.classNameModifiers, classNameModifiers = _f === void 0 ? [] : _f, focused = props.focused, filled = props.filled, isValid = props.isValid, _g = props.frontCVC, frontCVC = _g === void 0 ? false : _g, _h = props.hideCVCForBrand, hideCVCForBrand = _h === void 0 ? false : _h, _j = props.cvcRequired, cvcRequired = _j === void 0 ? true : _j;
    var i18n = useCoreContext().i18n;
    var fieldClassnames = classNames(className, (_a = {
            'adyen-checkout__field__cvc': true
        },
        _a[styles['adyen-checkout__card__cvc__input--hidden']] = hideCVCForBrand,
        _a['adyen-checkout__field__cvc--optional'] = !cvcRequired,
        _a));
    var cvcClassnames = classNames((_b = {
            'adyen-checkout__input': true,
            'adyen-checkout__input--small': true,
            'adyen-checkout__card__cvc__input': true,
            'adyen-checkout__input--error': error,
            'adyen-checkout__input--focus': focused,
            'adyen-checkout__input--valid': isValid
        },
        _b[styles['adyen-checkout__input']] = true,
        _b));
    var fieldLabel = cvcRequired ? label : i18n.get('creditCard.cvcField.title.optional');
    return (h(Field, { label: fieldLabel, focused: focused, filled: filled, classNameModifiers: __spreadArrays(classNameModifiers, ['securityCode']), onFocusField: function () { return onFocusField('encryptedSecurityCode'); }, className: fieldClassnames, errorMessage: !!error && i18n.get('creditCard.oneClickVerification.invalidInput.title'), isValid: isValid },
        h("span", { className: cvcClassnames, "data-cse": "encryptedSecurityCode" }),
        h(CVCHint, { frontCVC: frontCVC })));
}
