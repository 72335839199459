import { __assign } from "tslib";
import { getComponent, getComponentConfiguration } from '../..';
import { filterPresent, filterAvailable } from './filters';
var FALLBACK_COMPONENT = 'redirect';
/**
 * Returns a filtered (available) list of component Elements
 * @param components -
 * @param props - Props to be passed through to every component
 * @param componentsConfig - Specific config per payment method (where key is the name of the component)
 */
var createElements = function (components, props, componentsConfig) {
    if (components === void 0) { components = []; }
    if (componentsConfig === void 0) { componentsConfig = {}; }
    var createElement = function (component) {
        var componentProps = __assign(__assign(__assign(__assign({}, component), props), getComponentConfiguration(component.type, componentsConfig)), { isDropin: true });
        var componentInstance = getComponent(component.type, componentProps);
        // Fallback to redirect if payment method not available and no details are required
        if (!componentInstance && !component.details) {
            componentInstance = getComponent(FALLBACK_COMPONENT, componentProps);
        }
        return componentInstance;
    };
    var elements = components.map(createElement).filter(filterPresent);
    var elementPromises = elements.map(filterAvailable).map(function (p) { return p.catch(function (e) { return e; }); });
    return Promise.all(elementPromises).then(function (values) { return elements.filter(function (el, i) { return values[i] === true; }); });
};
export default createElements;
