import { __awaiter, __generator } from "tslib";
import { isReadyToPayRequest, initiatePaymentRequest } from './requests';
import { resolveEnvironment } from './utils';
import loadScript from '../../utils/loadScript';
import config from './config';
var GooglePayService = /** @class */ (function () {
    function GooglePayService(props) {
        var environment = resolveEnvironment(props.environment);
        if (environment === 'TEST' && process.env.NODE_ENV === 'development') {
            console.warn('Google Pay initiated in TEST mode. Request non-chargeable payment methods suitable for testing.');
        }
        this.paymentsClient = this.getGooglePaymentsClient({
            environment: environment,
            paymentDataCallbacks: props.paymentDataCallbacks
        });
    }
    /**
     * Initialize a Google Pay API client
     *
     * @see {@link https://developers.google.com/pay/api/web/reference/client#PaymentsClient|PaymentsClient constructor}
     * @returns Google Pay API client
     */
    GooglePayService.prototype.getGooglePaymentsClient = function (paymentOptions) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!!((_a = window.google) === null || _a === void 0 ? void 0 : _a.payments)) return [3 /*break*/, 2];
                        return [4 /*yield*/, loadScript(config.URL)];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/, new google.payments.api.PaymentsClient(paymentOptions)];
                }
            });
        });
    };
    /**
     * Determine a shopper's ability to return a form of payment from the Google Pay API.
     * @see {@link https://developers.google.com/pay/api/web/reference/client#isReadyToPay|isReadyToPay}
     */
    GooglePayService.prototype.isReadyToPay = function (props) {
        if (!this.paymentsClient)
            return Promise.reject(new Error('Google Pay is not available'));
        return this.paymentsClient.then(function (client) { return client.isReadyToPay(isReadyToPayRequest(props)); });
    };
    GooglePayService.prototype.prefetchPaymentData = function (props) {
        if (!this.paymentsClient)
            throw new Error('Google Pay is not available');
        var paymentDataRequest = initiatePaymentRequest(props);
        this.paymentsClient.then(function (client) { return client.prefetchPaymentData(paymentDataRequest); });
    };
    /**
     * Show Google Pay payment sheet when Google Pay payment button is clicked
     * @returns paymentData response from Google Pay API after user approves payment
     * @see {@link https://developers.google.com/pay/api/web/reference/object#PaymentData|PaymentData object reference}
     */
    GooglePayService.prototype.initiatePayment = function (props) {
        if (!this.paymentsClient)
            throw new Error('Google Pay is not available');
        var paymentDataRequest = initiatePaymentRequest(props);
        return this.paymentsClient.then(function (client) { return client.loadPaymentData(paymentDataRequest); });
    };
    return GooglePayService;
}());
export default GooglePayService;
