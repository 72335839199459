import { __assign } from "tslib";
import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import useCoreContext from '../../../core/Context/useCoreContext';
import Field from '../../../components/internal/FormFields/Field';
import getImage from '../../../utils/get-image';
import { renderFormField } from '../../../components/internal/FormFields';
import './BlikInput.scss';
function BlikInput(props) {
    var _this = this;
    var _a = useCoreContext(), i18n = _a.i18n, loadingContext = _a.loadingContext;
    var _b = useState(props.data), data = _b[0], setData = _b[1];
    var _c = useState({ blikCode: false }), errors = _c[0], setErrors = _c[1];
    var _d = useState({ blikCode: false }), valid = _d[0], setValid = _d[1];
    var handleBlikCode = function (type) { return function (e) {
        e.preventDefault();
        var value = e.target.value;
        var isBlikCodeValid = value.length === 6;
        setData({ blikCode: value });
        setErrors(__assign(__assign({}, valid), { blikCode: type === 'blur' && !isBlikCodeValid }));
        setValid(__assign(__assign({}, valid), { blikCode: isBlikCodeValid }));
    }; };
    useEffect(function () {
        props.onChange({ data: data, isValid: valid.blikCode }, _this);
    }, [data, valid, errors]);
    var _e = useState('ready'), status = _e[0], setStatus = _e[1];
    this.setStatus = function (newStatus) {
        setStatus(newStatus);
    };
    return (h("div", { className: "adyen-checkout__blik" },
        h("p", { className: "adyen-checkout__blik__helper" }, i18n.get('blik.help')),
        h(Field, { errorMessage: !!errors.blikCode && i18n.get('blik.invalid'), label: i18n.get('blik.code'), classNameModifiers: ['blikCode', '50'], isValid: valid.blikCode }, renderFormField('text', {
            value: data.blikCode,
            name: 'blikCode',
            spellcheck: false,
            required: true,
            autocorrect: 'off',
            onInput: handleBlikCode('input'),
            onChange: handleBlikCode('blur'),
            maxLength: 6
        })),
        props.showPayButton && props.payButton({ status: status, icon: getImage({ loadingContext: loadingContext, imageFolder: 'components/' })('lock') })));
}
BlikInput.defaultProps = { data: { blikCode: '' } };
export default BlikInput;
