import { __assign, __extends } from "tslib";
import { h } from 'preact';
import { UIElement } from '../UIElement';
import CardInput from './components/CardInput';
import CoreProvider from '../../core/Context/CoreProvider';
import getImage from '../../utils/get-image';
import collectBrowserInfo from '../../utils/browserInfo';
import handleBinLookUp from './handleBinLookUp';
var CardElement = /** @class */ (function (_super) {
    __extends(CardElement, _super);
    function CardElement() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onBrand = function (event) {
            _this.eventEmitter.emit('brand', __assign(__assign({}, event), { brand: event.brand === 'card' ? null : event.brand }));
            if (_this.props.onBrand)
                _this.props.onBrand(event);
        };
        _this.onBinValue = handleBinLookUp.bind(_this);
        return _this;
    }
    CardElement.prototype.formatProps = function (props) {
        return __assign(__assign(__assign(__assign({}, props), { 
            // Mismatch between hasHolderName & holderNameRequired which can mean card can never be valid
            holderNameRequired: !props.hasHolderName ? false : props.holderNameRequired, 
            // Special catch for recurring bcmc (i.e. card with no cvc field). Scenario?? - Dropin - One click with no details
            hasCVC: !((props.brand && props.brand === 'bcmc') || props.hideCVC), 
            // billingAddressRequired only available for non-stored cards
            billingAddressRequired: props.storedPaymentMethodId ? false : props.billingAddressRequired }), (props.brands && !props.groupTypes && { groupTypes: props.brands })), { type: props.type === 'scheme' ? 'card' : props.type, countryCode: props.countryCode ? props.countryCode.toLowerCase() : null });
    };
    /**
     * Formats the component data output
     */
    CardElement.prototype.formatData = function () {
        var cardBrand = this.state.additionalSelectValue || this.props.brand;
        var includeStorePaymentMethod = this.props.enableStoreDetails && typeof this.state.storePaymentMethod !== 'undefined';
        return __assign(__assign(__assign(__assign({ paymentMethod: __assign(__assign(__assign(__assign({ type: CardElement.type }, this.state.data), (this.props.storedPaymentMethodId && { storedPaymentMethodId: this.props.storedPaymentMethodId })), (cardBrand && { brand: cardBrand })), (this.props.fundingSource && { fundingSource: this.props.fundingSource })) }, (this.state.billingAddress && { billingAddress: this.state.billingAddress })), (includeStorePaymentMethod && { storePaymentMethod: Boolean(this.state.storePaymentMethod) })), (this.state.installments && this.state.installments.value && { installments: this.state.installments })), { browserInfo: this.browserInfo });
    };
    CardElement.prototype.updateStyles = function (stylesObj) {
        var _a;
        if ((_a = this.componentRef) === null || _a === void 0 ? void 0 : _a.updateStyles)
            this.componentRef.updateStyles(stylesObj);
        return this;
    };
    CardElement.prototype.setFocusOn = function (fieldName) {
        var _a;
        if ((_a = this.componentRef) === null || _a === void 0 ? void 0 : _a.setFocusOn)
            this.componentRef.setFocusOn(fieldName);
        return this;
    };
    CardElement.prototype.processBinLookupResponse = function (binLookupObject) {
        var _a;
        if ((_a = this.componentRef) === null || _a === void 0 ? void 0 : _a.processBinLookupResponse)
            this.componentRef.processBinLookupResponse(binLookupObject);
        return this;
    };
    CardElement.prototype.handleUnsupportedCard = function (errObj) {
        var _a;
        if ((_a = this.componentRef) === null || _a === void 0 ? void 0 : _a.handleUnsupportedCard)
            this.componentRef.handleUnsupportedCard(errObj);
        return this;
    };
    Object.defineProperty(CardElement.prototype, "isValid", {
        get: function () {
            return !!this.state.isValid;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CardElement.prototype, "icon", {
        get: function () {
            return getImage({ loadingContext: this.props.loadingContext })(this.brand);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CardElement.prototype, "brands", {
        get: function () {
            var _a = this.props, brands = _a.brands, loadingContext = _a.loadingContext;
            if (brands) {
                return brands.map(function (brand) { return ({
                    icon: getImage({ loadingContext: loadingContext })(brand),
                    name: brand
                }); });
            }
            return [];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CardElement.prototype, "brand", {
        get: function () {
            return this.props.brand || this.props.type;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CardElement.prototype, "displayName", {
        get: function () {
            if (this.props.storedPaymentMethodId) {
                return "\u2022\u2022\u2022\u2022 " + this.props.lastFour;
            }
            return this.props.name || CardElement.type;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CardElement.prototype, "browserInfo", {
        get: function () {
            return collectBrowserInfo();
        },
        enumerable: false,
        configurable: true
    });
    CardElement.prototype.render = function () {
        var _this = this;
        return (h(CoreProvider, { i18n: this.props.i18n, loadingContext: this.props.loadingContext },
            h(CardInput, __assign({ ref: function (ref) {
                    _this.componentRef = ref;
                } }, this.props, this.state, { onChange: this.setState, onSubmit: this.submit, payButton: this.payButton, onBrand: this.onBrand, onBinValue: this.onBinValue, brand: this.brand }))));
    };
    CardElement.type = 'scheme';
    return CardElement;
}(UIElement));
export { CardElement };
export default CardElement;
