import { h } from 'preact';
import CardNumber from './CardNumber';
import CVC from './CVC';
import ExpirationDate from './ExpirationDate';
import useCoreContext from '../../../../../core/Context/useCoreContext';
export default function CardFields(_a) {
    var brand = _a.brand, cvcRequired = _a.cvcRequired, dualBrandingElements = _a.dualBrandingElements, dualBrandingChangeHandler = _a.dualBrandingChangeHandler, dualBrandingSelected = _a.dualBrandingSelected, errors = _a.errors, focusedElement = _a.focusedElement, hasCVC = _a.hasCVC, hideCVCForBrand = _a.hideCVCForBrand, onFocusField = _a.onFocusField, showBrandIcon = _a.showBrandIcon, valid = _a.valid;
    var i18n = useCoreContext().i18n;
    return (h("div", { className: "adyen-checkout__card__form" },
        h(CardNumber, { brand: brand, error: !!errors.encryptedCardNumber, focused: focusedElement === 'encryptedCardNumber', isValid: !!valid.encryptedCardNumber, label: i18n.get('creditCard.numberField.title'), onFocusField: onFocusField, filled: !!errors.encryptedCardNumber || !!valid.encryptedCardNumber, showBrandIcon: showBrandIcon, dualBrandingElements: dualBrandingElements, dualBrandingChangeHandler: dualBrandingChangeHandler, dualBrandingSelected: dualBrandingSelected }),
        h("div", { className: "adyen-checkout__card__exp-cvc adyen-checkout__field-wrapper" },
            h(ExpirationDate, { error: !!errors.encryptedExpiryDate || !!errors.encryptedExpiryYear || !!errors.encryptedExpiryMonth, focused: focusedElement === 'encryptedExpiryDate', isValid: !!valid.encryptedExpiryMonth && !!valid.encryptedExpiryYear, filled: !!errors.encryptedExpiryDate || !!valid.encryptedExpiryYear, label: i18n.get('creditCard.expiryDateField.title'), onFocusField: onFocusField, className: 'adyen-checkout__field--50' }),
            hasCVC && (h(CVC, { cvcRequired: cvcRequired, error: !!errors.encryptedSecurityCode, focused: focusedElement === 'encryptedSecurityCode', hideCVCForBrand: hideCVCForBrand, isValid: !!valid.encryptedSecurityCode, filled: !!errors.encryptedSecurityCode || !!valid.encryptedSecurityCode, label: i18n.get('creditCard.cvcField.title'), onFocusField: onFocusField, className: 'adyen-checkout__field--50', frontCVC: brand === 'amex' })))));
}
