import { __assign, __extends } from "tslib";
import { h } from 'preact';
import UIElement from '../UIElement';
import ThreeDS2DeviceFingerprint from './components/DeviceFingerprint';
/**
 * ThreeDS2DeviceFingerprintElement
 */
var ThreeDS2DeviceFingerprintElement = /** @class */ (function (_super) {
    __extends(ThreeDS2DeviceFingerprintElement, _super);
    function ThreeDS2DeviceFingerprintElement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ThreeDS2DeviceFingerprintElement.prototype.render = function () {
        return h(ThreeDS2DeviceFingerprint, __assign({}, this.props, { onComplete: this.onComplete }));
    };
    ThreeDS2DeviceFingerprintElement.type = 'threeDS2Fingerprint';
    ThreeDS2DeviceFingerprintElement.defaultProps = {
        dataKey: 'threeds2.fingerprint',
        deviceFingerPrintContainer: null,
        type: 'IdentifyShopper',
        notificationURL: null,
        onComplete: function () { }
    };
    return ThreeDS2DeviceFingerprintElement;
}(UIElement));
export default ThreeDS2DeviceFingerprintElement;
