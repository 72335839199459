var defaultProps = {
    environment: 'TEST',
    status: 'loading',
    // Config
    /**
     * @see {@link https://developer.paypal.com/docs/checkout/reference/customize-sdk/#merchant-id}
     */
    merchantId: '',
    /**
     * @see {@link https://developer.paypal.com/docs/checkout/integration-features/customize-button/}
     */
    style: {
        /**
         * The height of the buttons is set to 48px to align with the default size of our Pay buttons.
         */
        height: 48
    },
    // Events
    onSubmit: function () { },
    onAdditionalDetails: function () { },
    onInit: function () { },
    onClick: function () { },
    onCancel: function () { },
    onError: function () { }
};
export default defaultProps;
