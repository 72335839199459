import { h } from 'preact';
import classNames from 'classnames';
import Field from '../../../../internal/FormFields/Field';
import useCoreContext from '../../../../../core/Context/useCoreContext';
import styles from '../CardInput.module.scss';
export default function ExpirationDate(props) {
    var label = props.label, focused = props.focused, filled = props.filled, onFocusField = props.onFocusField, _a = props.className, className = _a === void 0 ? '' : _a, _b = props.error, error = _b === void 0 ? false : _b, _c = props.isValid, isValid = _c === void 0 ? false : _c;
    var i18n = useCoreContext().i18n;
    return (h(Field, { label: label, classNameModifiers: ['expiryDate'], className: className, focused: focused, filled: filled, onFocusField: function () { return onFocusField('encryptedExpiryDate'); }, errorMessage: error && i18n.get('creditCard.expiryDateField.invalid'), isValid: isValid },
        h("span", { "data-cse": "encryptedExpiryDate", className: classNames('adyen-checkout__input', 'adyen-checkout__input--small', 'adyen-checkout__card__exp-date__input', [styles['adyen-checkout__input']], {
                'adyen-checkout__input--error': error,
                'adyen-checkout__input--focus': focused,
                'adyen-checkout__input--valid': isValid
            }) })));
}
// export default ExpirationDate;
