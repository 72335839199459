import { __assign, __extends } from "tslib";
import { h } from 'preact';
import OpenInvoiceContainer from '../helpers/OpenInvoiceContainer';
import ConsentCheckbox from './components/ConsentCheckbox';
var AfterPay = /** @class */ (function (_super) {
    __extends(AfterPay, _super);
    function AfterPay() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AfterPay.prototype.formatProps = function (props) {
        return __assign(__assign({}, _super.prototype.formatProps.call(this, props)), { consentCheckbox: function (props) { return h(ConsentCheckbox, __assign({}, props)); } });
    };
    AfterPay.type = 'afterpay_default';
    return AfterPay;
}(OpenInvoiceContainer));
export default AfterPay;
