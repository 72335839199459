import { __assign, __extends } from "tslib";
import { h } from 'preact';
import UIElement from '../UIElement';
import PhoneInput from '../internal/PhoneInput';
import CoreProvider from '../../core/Context/CoreProvider';
import { formatPrefixName, selectItem } from './utils';
import getProp from '../../utils/getProp';
var QiwiWalletElement = /** @class */ (function (_super) {
    __extends(QiwiWalletElement, _super);
    function QiwiWalletElement(props) {
        var _this = _super.call(this, props) || this;
        _this.props.items = _this.props.items.map(formatPrefixName).filter(function (item) { return item !== false; });
        return _this;
    }
    Object.defineProperty(QiwiWalletElement.prototype, "isValid", {
        get: function () {
            return !!this.state.isValid;
        },
        enumerable: false,
        configurable: true
    });
    QiwiWalletElement.prototype.formatProps = function (props) {
        var items = getProp(props, 'details.0.items') || props.items;
        return __assign(__assign({ onValid: function () { } }, props), { prefixName: getProp(props, 'details.0.key') || 'qiwiwallet.telephoneNumberPrefix', phoneName: getProp(props, 'details.1.key') || 'qiwiwallet.telephoneNumber', selected: selectItem(items, props.countryCode), items: items });
    };
    /**
     * Formats the component data output
     */
    QiwiWalletElement.prototype.formatData = function () {
        return {
            paymentMethod: {
                type: QiwiWalletElement.type,
                'qiwiwallet.telephoneNumberPrefix': this.state.data ? this.state.data.phonePrefix : '',
                'qiwiwallet.telephoneNumber': this.state.data ? this.state.data.phoneNumber : ''
            }
        };
    };
    QiwiWalletElement.prototype.render = function () {
        var _this = this;
        return (h(CoreProvider, { i18n: this.props.i18n, loadingContext: this.props.loadingContext },
            h(PhoneInput, __assign({ ref: function (ref) {
                    _this.componentRef = ref;
                } }, this.props, this.state, { onChange: this.setState, onSubmit: this.submit, payButton: this.payButton }))));
    };
    QiwiWalletElement.type = 'qiwiwallet';
    QiwiWalletElement.defaultProps = {
        items: [],
        countryCode: null
    };
    return QiwiWalletElement;
}(UIElement));
export default QiwiWalletElement;
