import { __assign, __extends } from "tslib";
import { h } from 'preact';
import UIElement from '../UIElement';
import AchInput from './components/AchInput';
import CoreProvider from '../../core/Context/CoreProvider';
var AchElement = /** @class */ (function (_super) {
    __extends(AchElement, _super);
    function AchElement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AchElement.prototype.formatProps = function (props) {
        return __assign(__assign({}, props), { 
            // Fix mismatch between passed hasHolderName & holderNameRequired props
            // (when holderNameRequired = true, but hasHolderName = false - which means component will never be valid)
            holderNameRequired: props.hasHolderName === false ? false : props.holderNameRequired });
    };
    /**
     * Formats the component data output
     */
    AchElement.prototype.formatData = function () {
        // Map holderName to ownerName
        var paymentMethod = __assign(__assign({ type: AchElement.type }, this.state.data), { ownerName: this.state.data.holderName });
        delete paymentMethod.holderName;
        return __assign({ paymentMethod: paymentMethod }, (this.state.billingAddress && { billingAddress: this.state.billingAddress }));
    };
    AchElement.prototype.updateStyles = function (stylesObj) {
        if (this.componentRef && this.componentRef.updateStyles)
            this.componentRef.updateStyles(stylesObj);
        return this;
    };
    AchElement.prototype.setFocusOn = function (fieldName) {
        if (this.componentRef && this.componentRef.setFocusOn)
            this.componentRef.setFocusOn(fieldName);
        return this;
    };
    Object.defineProperty(AchElement.prototype, "isValid", {
        get: function () {
            return !!this.state.isValid;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AchElement.prototype, "displayName", {
        get: function () {
            return this.props.name;
        },
        enumerable: false,
        configurable: true
    });
    AchElement.prototype.render = function () {
        var _this = this;
        return (h(CoreProvider, { i18n: this.props.i18n, loadingContext: this.props.loadingContext },
            h(AchInput, __assign({ ref: function (ref) {
                    _this.componentRef = ref;
                } }, this.props, { onChange: this.setState, onSubmit: this.submit, payButton: this.payButton }))));
    };
    AchElement.type = 'ach';
    return AchElement;
}(UIElement));
export { AchElement };
export default AchElement;
