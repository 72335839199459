import { getImageUrl } from '../../../../utils/get-image';
import cardType from '../../../../components/internal/SecuredFields/lib/utilities/cardType';
export var getCardImageUrl = function (brand, loadingContext) {
    var imageOptions = {
        type: brand === 'card' ? 'nocard' : brand || 'nocard',
        extension: 'svg',
        loadingContext: loadingContext
    };
    return getImageUrl(imageOptions)(brand);
};
/**
 * Creates an object used for setting state - that will trigger the rendering of a select element to allow a choice between 2 different card variants
 * @param types - array containing 2 card brands or types
 * @param switcherType - type of switcher ('brandSwitcher' or 'cardTypeSwitcher' - the latter would switch between 'debit' & 'credit' varieties)
 */
export var createCardVariantSwitcher = function (types, switcherType) {
    var leadType = types[0];
    var displayName = cardType.getDisplayName(leadType); // Works for when types are card brands e.g. 'visa', 'mc' NOT when types are 'credit'/'debit'
    var leadDisplayName = displayName || leadType;
    var subType = types[1];
    displayName = cardType.getDisplayName(subType);
    var subDisplayName = displayName || subType;
    return {
        stateObject: {
            additionalSelectElements: [
                { id: leadType, name: leadDisplayName },
                { id: subType, name: subDisplayName }
            ],
            // additionalSelectValue: leadType, // comment out line if no initial selection is to be made
            additionalSelectType: switcherType
        },
        leadType: leadType
    };
};
