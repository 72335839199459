import { __assign, __extends } from "tslib";
import { h } from 'preact';
import UIElement from '../UIElement';
import QRLoader from '../internal/QRLoader';
import CoreProvider from '../../core/Context/CoreProvider';
var QRLoaderContainer = /** @class */ (function (_super) {
    __extends(QRLoaderContainer, _super);
    function QRLoaderContainer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    QRLoaderContainer.prototype.formatData = function () {
        return {
            paymentMethod: __assign({ type: this.props.type || this.constructor['type'] }, this.state.data)
        };
    };
    Object.defineProperty(QRLoaderContainer.prototype, "isValid", {
        get: function () {
            return true;
        },
        enumerable: false,
        configurable: true
    });
    QRLoaderContainer.prototype.render = function () {
        var _this = this;
        if (this.props.paymentData) {
            return (h(CoreProvider, { i18n: this.props.i18n, loadingContext: this.props.loadingContext },
                h(QRLoader, __assign({ ref: function (ref) {
                        _this.componentRef = ref;
                    } }, this.props, { shouldRedirectOnMobile: this.props.shouldRedirectOnMobile, type: this.constructor['type'], brandLogo: this.props.brandLogo || this.icon, delay: this.props.delay, onComplete: this.onComplete, countdownTime: this.props.countdownTime }))));
        }
        if (this.props.showPayButton) {
            return this.payButton({
                label: this.props.buttonLabel ? this.props.i18n.get(this.props.buttonLabel) : this.props.i18n.get('continue'),
                classNameModifiers: ['standalone']
            });
        }
        return null;
    };
    QRLoaderContainer.defaultProps = {
        qrCodeImage: '',
        amount: null,
        paymentData: null,
        onError: function () { },
        onComplete: function () { }
    };
    return QRLoaderContainer;
}(UIElement));
export default QRLoaderContainer;
